<template>
  <v-main>
    <router-view :key="$route.fullPath" />

    <c-v-footer />
  </v-main>
</template>

<script>
/* eslint-disable */
  export default {
    name: 'CVView',

    components: {
      CVFooter: () => import('./CVFooter'),
    },
    props: {
      // key: {
      //   type: String,
      //   default: '0'
      // }
    }
  }
</script>
